@import "~antd/dist/antd.css";

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
body {
 font-size: 16px;
 font-family: Montserrat;
 font-weight: normal;
 color: #000000;
}

.ant-input-password {
	height: 40px;
}

.ant-table-wrapper {
	overflow: hidden;
	overflow-x: scroll;
}

.ant-btn-primary {
	background-color: #51d8d9;
	border-color: #51d8d9;
}

.ant-btn-primary:focus {
	background-color: #51d8d9;
	border-color: #51d8d9;
}

.ant-btn-primary:hover {
	background-color: #51d8d9;
	border-color: #51d8d9;
}